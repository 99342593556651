import { ImageTransformOptions, sdk } from '@wix/image-kit';

const WIX_MEDIA_PREFIX = 'https://static.wixstatic.com/media/';
const WIX_MEDIA_DOMAIN = '//static.wixstatic.com';

const isExternalUrl = (url: string) => {
  return /(^https?)|(^data)|(^blob)/.test(url);
};

const isWixMediaUrl = (url: string) => url.indexOf(WIX_MEDIA_DOMAIN) >= 0;

const getWixFilename = (url: string) => url.replace(WIX_MEDIA_PREFIX, '');

const prefixUrlIfNeeded = (originalUrl: string) => {
  if (isExternalUrl(originalUrl)) {
    return originalUrl;
  } else {
    return WIX_MEDIA_PREFIX + originalUrl;
  }
};

const resizeVideoImp = (
  item: any,
  originalUrl: string,
  requiredWidth: number,
  requiredHeight: number,
) => {
  let videoUrl = originalUrl;

  if (item.qualities && item.qualities.length) {
    let suffix = '/';

    const mp4Qualities = item.qualities.filter(
      (video: any) => video.formats[0] === 'mp4',
    );
    // search for the first quality bigger that the required one
    for (let q = 0; q < mp4Qualities.length; q++) {
      const quality = mp4Qualities[q];
      if (quality.height >= requiredHeight || !mp4Qualities[q + 1]) {
        suffix += quality.quality; // e.g. 720p

        for (const format of quality.formats) {
          videoUrl =
            '//video.wixstatic.com/video/' +
            item.url +
            suffix +
            '/' +
            format +
            '/file.' +
            format;
        }

        break;
      }
    }

    return videoUrl;
    // const wixQualities = this.qualities.filter(video => video.formats[0] === 'wix');
    // urls.wix = window.location.protocol + '//video.wixstatic.com/video/' + this.url + suffix + '/' + format + '/file.' + format;
  }
};

type ResizeUrlImpSdkParams = {
  item: any;
  originalUrl: string;
  resizeMethod: string;
  requiredWidth: number;
  requiredHeight: number;
  sharpParams: any;
  focalPoint: any;
  useWebp?: boolean;
  devicePixelRatio?: number;
};

const resizeUrlImp_sdk = ({
  item,
  originalUrl,
  resizeMethod,
  requiredHeight,
  requiredWidth,
  sharpParams,
  focalPoint,
  useWebp,
  devicePixelRatio,
}: ResizeUrlImpSdkParams) => {
  // assign default parameters
  originalUrl = originalUrl || '';
  sharpParams = sharpParams || {};

  // calc default quality
  if (sharpParams.quality > 0) {
    // don't allow quality above 90 till we have proper UI indication
    sharpParams.quality = Math.min(90, sharpParams.quality);
  }

  const focalPointObj = { x: 50, y: 50 };

  if (focalPoint && focalPoint[0] >= 0 && focalPoint[1] >= 0) {
    focalPointObj.x = Math.round(focalPoint[0] * 100);
    focalPointObj.y = Math.round(focalPoint[1] * 100);
  }

  if (sharpParams.allowUsm === true && sharpParams.usm) {
    sharpParams.usm.usm_a = Math.min(
      5,
      Math.max(0, sharpParams.usm.usm_a || 0),
    );
    sharpParams.usm.usm_r = Math.min(
      128,
      Math.max(0, sharpParams.usm.usm_r || 0),
    ); // should be max 500 - but it's returning a 404
    sharpParams.usm.usm_t = Math.min(
      1,
      Math.max(0, sharpParams.usm.usm_t || 0),
    );
  } else {
    sharpParams.usm = {
      usm_a: 0,
      usm_r: 0,
      usm_t: 0,
    };
  }

  if (isExternalUrl(originalUrl) && !isWixMediaUrl(originalUrl)) {
    return originalUrl;
  } else {
    const resizer =
      resizeMethod === 'fit'
        ? sdk.getScaleToFitImageURL
        : sdk.getScaleToFillImageURL;

    /**
     * the transform options
     * @typedef  {object}   ImageTransformOptions
     * @property {boolean}  [progressive]               image transform progressive
     * @property {number}   [quality]                   image transform quality
     * @property {string}   [watermark]                 image watermark id
     * @property {object}   [unsharpMask]               unsharpMask filter
     * @property {number}   [unsharpMask.radius]        unsharpMask radius
     * @property {number}   [unsharpMask.amount]        unsharpMask amount
     * @property {number}   [unsharpMask.threshold]     unsharpMask threshold
     */

    const options: ImageTransformOptions = { devicePixelRatio };

    if (sharpParams.quality > 0) {
      options.quality = sharpParams.quality;
    }

    if (sharpParams.blur > 0) {
      options.filters = {
        blur: sharpParams.blur,
      };
    }

    if (focalPointObj) {
      options.focalPoint = focalPointObj;
    }

    if (sharpParams && sharpParams.usm) {
      options.unsharpMask = {
        radius: parseFloat(sharpParams.usm.usm_r),
        amount: parseFloat(sharpParams.usm.usm_a),
        threshold: parseFloat(sharpParams.usm.usm_t),
      };
    }

    const retUrl = resizer(
      getWixFilename(originalUrl),
      item.maxWidth,
      item.maxHeight,
      devicePixelRatio ? requiredWidth * devicePixelRatio : requiredWidth,
      devicePixelRatio ? requiredHeight * devicePixelRatio : requiredHeight,
      options,
    );

    if (useWebp) {
      return retUrl.replace(/[^.]\w*$/, 'webp');
    }

    return retUrl;
  }
};

type ResizeMediaUrlParams = {
  item: any;
  originalUrl: string;
  resizeMethod: string;
  requiredWidth: number;
  requiredHeight: number;
  sharpParams: any;
  focalPoint: any;
};

const resizeMediaUrl = ({
  item,
  originalUrl,
  resizeMethod,
  requiredWidth,
  requiredHeight,
  sharpParams,
  focalPoint,
}: ResizeMediaUrlParams) => {
  // remove resizing parameters if exists
  const resizingParamerterRegex = /\/v\d\/(fill|fit)\/w_\d*(,|%2C)h_\d*/;
  const resizingParametersPosition = resizingParamerterRegex.exec(originalUrl);
  if (resizingParametersPosition && resizingParametersPosition.index > 0) {
    originalUrl = originalUrl.substr(0, resizingParametersPosition.index);
  }

  if (resizeMethod === 'video') {
    return resizeVideoImp(item, originalUrl, requiredWidth, requiredHeight);
  } else if (resizeMethod === 'full') {
    return prefixUrlIfNeeded(originalUrl);
  } else {
    return resizeUrlImp_sdk({
      item,
      originalUrl,
      resizeMethod,
      requiredWidth,
      requiredHeight,
      sharpParams,
      focalPoint,
    });
  }
};

const resizeMediaUrlV2 = ({
  item,
  originalUrl,
  resizeMethod,
  requiredWidth,
  requiredHeight,
  sharpParams,
  focalPoint,
}: ResizeMediaUrlParams) => {
  // remove resizing parameters if exists
  const resizingParamerterRegex = /\/v\d\/(fill|fit)\/w_\d*(,|%2C)h_\d*/;
  const resizingParametersPosition = resizingParamerterRegex.exec(originalUrl);

  if (resizingParametersPosition && resizingParametersPosition.index > 0) {
    originalUrl = originalUrl.substr(0, resizingParametersPosition.index);
  }

  if (resizeMethod === 'video') {
    return resizeVideoImp(item, originalUrl, requiredWidth, requiredHeight);
  } else if (resizeMethod === 'full') {
    return prefixUrlIfNeeded(originalUrl);
  } else {
    return [
      {
        type: originalUrl?.match?.(/[^.]\w*$/)?.[0],
        url: resizeUrlImp_sdk({
          item,
          originalUrl,
          resizeMethod,
          requiredWidth,
          requiredHeight,
          sharpParams,
          focalPoint,
          useWebp: false,
          devicePixelRatio: 1,
        }),
        dpr: [1, 2]
          .map(
            (dpr) =>
              resizeUrlImp_sdk({
                item,
                originalUrl,
                resizeMethod,
                requiredWidth,
                requiredHeight,
                sharpParams,
                focalPoint,
                useWebp: false,
                devicePixelRatio: dpr,
              }) + ` ${dpr}x`,
          )
          .join(', '),
      },
      {
        type: 'webp',
        url: resizeUrlImp_sdk({
          item,
          originalUrl,
          resizeMethod,
          requiredWidth,
          requiredHeight,
          sharpParams,
          focalPoint,
          useWebp: true,
          devicePixelRatio: 1,
        }),
        dpr: [1, 2]
          .map(
            (dpr) =>
              resizeUrlImp_sdk({
                item,
                originalUrl,
                resizeMethod,
                requiredWidth,
                requiredHeight,
                sharpParams,
                focalPoint,
                useWebp: true,
                devicePixelRatio: dpr,
              }) + ` ${dpr}x`,
          )
          .join(', '),
      },
    ];
  }
};

export { resizeMediaUrl, resizeMediaUrlV2, WIX_MEDIA_PREFIX };
